<template>
    <div class="oreder-bet-list">
        <h3>Заказы ставок. Найдено <span>{{totalElements}}</span></h3>
        <div>
            <div style="width: 150px; display: inline-block">
                <b-form-select v-model="selectedBookmaker"
                           :options="bookmakers"
                           aria-placeholder="sss"
                           class="mb-2"></b-form-select>
            </div>
            |
            <div style="width: 150px; display: inline-block">
                <b-form-select v-model="selectedStatus"
                               :options="statuses"
                               aria-placeholder="sss"
                               class="mb-2"></b-form-select>
            </div>
            |
            <div style="width: 350px; display: inline-block">
                <b-form-datepicker id="filter-play-at"
                                   placeholder="Дата матча"
                                   v-model="filterPlayAt"
                                   reset-button
                                   class="mb-2"></b-form-datepicker>
            </div>
            |
            <div style="width: 350px; display: inline-block">
                <b-form-datepicker id="filter-created-at"
                                   placeholder="Дата создания"
                                   v-model="filterCreatedAt"
                                   reset-button
                                   class="mb-2"></b-form-datepicker>
            </div>
            |
            <div style="width: 350px; display: inline-block">
              <input type="text" v-model="searchUser" class="form-control"

                     id="inlineFormInputGroupUsername" placeholder="Автор">
            </div>
        </div>

        <div class="mb-2">
          <div style="width: 350px; display: inline-block">
            <input type="text" v-model="filterBookOrderId" class="form-control"
              id="inlineFormInputGroupfilterBookOrderId" placeholder="Book order">
          </div>
            <div class="ml-2 d-inline-block">
              <button class="btn btn-success btn-sm" v-on:click="loadOrders">
                Найти
                  <!-- <b-icon icon="arrow-repeat"></b-icon> -->
              </button>
            </div>
        </div>

        <table id="order-bet-list-table" class="table order-bet-list-table">
            <tr>
                <th>ID</th>
                <th>Event</th>
                <th>Bet Type</th>
                <th>Want</th>
                <th>Actual</th>
                <th>Risk</th>
                <th>Profit, EUR</th>
                <th>Status</th>
                <th>Last Price</th>
                <th>UDI</th>
                <th>Отчисление</th>
                <th>Created At</th>
            </tr>
            <tr v-for="ob in orders" v-bind:key="ob.id">
                <td width="150px">
                    <router-link :to="{ name: 'OrderBetsSingle', params: { id: ob.id }}">{{ ob.id }}</router-link>
                    <br/>
                    <small v-if="ob.id != ob.parentOrderId">
                      <router-link :to="{ name: 'OrderBetsListGroupBet', params: { parentOrderId: ob.parentOrderId }}">Группа</router-link>
                    </small>
                    <br/>
                    <small><router-link :to="{ name: 'IncomeBetsSingle', params: { id: ob.incomeBetId }}">IncomeBet</router-link></small>
                </td>
                <td>{{ getBookmakerName(ob.bookmakerId) }}. {{getSportName(ob.event.sport)}}.
                    {{ob.event.tournamentName}}<br/>
                    {{ ob.event.home }} - {{ ob.event.away }}<br/>
                    {{ new Date(ob.event.playAt) | moment("YYYY-MM-DD HH:mm") }}  | {{ ob.event.eventId }}<br/>

                    <a v-if="null != ob.blog" :href="getBlogUrl(ob.blog.id)" target="_blank">{{ob.blog.title}}</a>
                    |
                    <a :href="getCpanelTopicUrl(ob.incomeBetId)" target="_blank">Topic</a>
                </td>
                <td width="150px">
                  <p class="mb-1">
                    <span v-if="ob.betName=='special'">{{ob.description}}</span>
                    <span v-else>{{ob.betName}}</span>                    
                  </p>
                  <p class="mb-1">{{ob.period}}</p>
                  <p class="mb-1" v-if="ob.selectedBook">
                    {{ob.selectedBook}}
                  </p>
                </td>
                <td>
                  {{ ob.wantStake }} / {{ ob.wantPrice }}
                  <br>
                  <small>[Origin: {{ob.originWantStake}}]</small>
                </td>
                <td>{{ ob.stake }} / {{ ob.price }}</td>
                <td>{{ ob.minRisk }} - {{ ob.maxRisk }}</td>
                <td>{{ ob.profit }}</td>
                <td>
                  {{ ob.status }}
                  <template v-if="ob.isPartialVoid">
                    <br/><b class="text-danger">Partial Void</b>
                  </template>
                  <template v-if="ob.cancelReason">
                    <br/>{{ob.cancelReason}}
                  </template>
                </td>
                <td>{{ ob.lastPrice }}</td>
                <td>{{ ob.udi }}</td>
                <td>{{ ob.deduction }}</td>
                <td>{{ new Date(ob.createdAt) | moment("YYYY-MM-DD HH:mm") }}</td>
            </tr>
        </table>
        <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                v-on:change="selectPage"
                aria-controls="users-list"
        ></b-pagination>

        <div v-if="summaryOfBets.length > 0">
          <table>
            <tr>
              <th>User Id</th>
              <th>Placed stake</th>
              <th>Payed commission</th>
              <!-- <th>Calculated percent</th> -->
            </tr>
            <tr v-for="placed in summaryOfBets" v-bind:key="placed.id">
              <td>{{placed.userId}}</td>
              <td>{{placed.placedStake}}</td>
              <td>{{placed.commission}}</td>
              <!-- <td>{{placed.percent}}</td> -->
            </tr>
            <tr>
              <td><b>Всего</b></td>
              <td>{{ calcSumPlacedStakes() }}</td>
              <td>{{ calcSumCommission() }}</td>
              <!-- <td>{{placed.percent}}</td> -->
            </tr>
          </table>
          
        </div>
    </div>
</template>

<script>
    import OrderBetService from "../services/OrderBetService";
    import {BookmakerType, SportType} from "../services/enums"

    export default {
        name: "OrderBetsList",
        data: function(){
            return {
                totalElements: 0,
                currentPage: 1,
                totalRows: 0,
                perPage:1,
                orders: [],
                statuses: [
                    {value: null, text:'Статус'},
                    {value: 'OPEN', text:'Open'},
                    {value: 'PLAY', text:'Play'},
                    {value: 'WON', text:'Won'},
                    {value: 'HALF_WON', text:'Half won'},
                    {value: 'LOSS', text:'Loss'},
                    {value: 'HALF_LOSS', text:'Half loss'},
                    {value: 'REFUND', text:'Refund'},
                    {value: 'CANCEL', text:'Cancel'},
                    {value: 'FAILED', text:'Failed'},
                    {value: 'FAKE', text:'Fake'},
                    {value: 'NEW', text:'New'},
                ],
                selectedStatus: null,
                filterCreatedAt: '',
                filterPlayAt: '',
                filterBookOrderId: '',

                bookmakers: [
                  {value: null, text:'Букмекер'},
                  {value: 1, text: 'Pinnacle'},
                  {value: 3, text: 'Mollybet'},
                  {value: 4, text: 'Vodds'},
                ],
                selectedBookmaker: null,
                parentOrderId: null, 
                summaryOfBets: [],
              searchUser: ""
            }
        },
        methods: {
            selectPage(page) {
                this.currentPage=page;
                this.loadOrders()
            },
            loadOrders(){
                var self = this;
                var filter = {};
                if (this.selectedStatus != null) {
                    filter["status"] = this.selectedStatus;
                }
                if (this.filterPlayAt != '') {
                    filter["playAt"] = this.filterPlayAt;
                }
                if (this.filterCreatedAt != '') {
                    filter["createdAt"] = this.filterCreatedAt;
                }

                if (this.searchUser != '') {
                    filter["searchUser"] = this.searchUser
                }

                if (this.selectedBookmaker != null) {
                    filter["bookmakerId"] = this.selectedBookmaker;
                }

                if (this.filterBookOrderId != null && this.filterBookOrderId != '') {
                    filter["bookOrderId"] = this.filterBookOrderId;
                }

              if (this.parentOrderId != null) {
                filter["parentOrderId"] = this.parentOrderId;
              }

                OrderBetService.getOrderBetsList(self.currentPage-1, filter)
                    .then((response) => {
                      var page = response.data.page;
                        self.orders = page.content;

                        self.totalElements = page.totalElements;
                        self.currentPage = page.number+1;
                        self.totalRows = page.totalElements;
                        self.perPage = page.size;

                        if (response.data.summaryOfBets !== undefined) {
                          self.summaryOfBets = response.data.summaryOfBets;
                        }
                    })
            },
            getBookmakerName(id) {
                return BookmakerType[id];
            },
            getSportName(id) {
                return SportType[id];
            },
            getBlogUrl(id) {
                return 'https://expari.com/blog/' + id
            },
            getCpanelTopicUrl(id) {
                return 'https://expari.com/cpanel/topics/bet/' + id
            },
            calcSumPlacedStakes() {
              let sum = 0
              this.summaryOfBets.forEach(it => {
                sum += it.placedStake
              })
              return sum;
            },
            calcSumCommission() {
              let sum = 0
              this.summaryOfBets.forEach(it => {
                sum += it.commission
              })
              return sum;
            }
        },
        watch: {
            selectedStatus: function() {
                this.loadOrders();
            },
            filterPlayAt: function() {
                this.loadOrders();
            },
            filterCreatedAt: function() {
                this.loadOrders();
            },
            selectedBookmaker: function() {
              this.loadOrders();
            },
            searchUser: function (val) {
              if (val == '' || val.length >=1) {
                this.loadOrders();
              }
            }
        },
        mounted() {

            if (null != this.$route.params.parentOrderId) {
              this.parentOrderId = this.$route.params.parentOrderId;
            }

            this.loadOrders()
        }
    }
</script>
